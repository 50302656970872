import React, { useEffect, useState, FC } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { nameof } from '../utils/typescript-helpers';
import { BaseForm } from '../definitions/base-form';
import { clearFormErrors } from '../utils/handle-errors';
import { parse } from 'query-string';
import { PageLayout } from '../components/layout';
import { ContactUsThankYou } from '../components/shared';
import Fade from 'react-reveal/Fade';
import { MainContent } from '../components/layout/main-content';

import {
  sendApiV1Contact,
  AppValidationWrapper,
  AppIcon,
  useRecaptcha,
  SendContactForm,
  ERROR_MESSAGES,
  DEFAULTS,
  getTargetForTopic,
  CONTACT_US_TOPIC,
} from 'common';
import useIsClient from '../hooks/use-is-client';
import { EXTERNAL_ROUTES } from '../routes';

const ContactUsHeader: FC = () => {
  /**
   * DOM
   */
  return (
    <div className="mt-10">
      <div className="flex flex-col items-center align-middle">
        <AppIcon icon="chat-filled" size={60} />
        <h2 className="ff-secondary mt-4 font-bold text-center">Contact us</h2>
        <p className="text-grey-darker w-full md:w-5/12 text-center text-xl mt-6">
          We want you to have the most positive experience possible with Stablehouse. If
          you have any queries or need extra support, please do not hesitate to contact
          us.
        </p>
      </div>
    </div>
  );
};

interface ContactUsForm extends BaseForm {
  topic: string;
  fullname: string;
  email: string;
  message: string;
  recaptchaToken: string;
}

const schema = Yup.object().shape({
  [nameof<ContactUsForm>('topic')]: Yup.string().required(ERROR_MESSAGES.REQUIRED_VALUE),
  [nameof<ContactUsForm>('fullname')]: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .min(DEFAULTS.CHAR_LENGTH_2, ERROR_MESSAGES.MIN_2_CHARACTERS)
    .max(DEFAULTS.CHAR_LENGTH_255, ERROR_MESSAGES.MAX_255_CHARACTERS),
  [nameof<ContactUsForm>('email')]: Yup.string()
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .max(DEFAULTS.CHAR_LENGTH_255, ERROR_MESSAGES.MAX_255_CHARACTERS),
  [nameof<ContactUsForm>('message')]: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .max(DEFAULTS.CHAR_LENGTH_500, ERROR_MESSAGES.MAX_500_CHARACTERS),
});

interface ContactUsFormProps {
  location: any;
  onSent: (value: boolean) => void;
}

const ContactUsForm: FC<ContactUsFormProps> = ({ onSent, location }) => {
  /**
   * Hooks
   */
  const { isClient } = useIsClient();

  /**
   * State
   */
  const [error, setError] = useState<string | null>(null);
  const { getRecaptchaToken } = useRecaptcha({
    key:
      typeof window === 'undefined' || (typeof window !== 'undefined' && window.Cypress)
        ? process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY_CYPRESS || ''
        : process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY || '',
  });

  /**
   * Methods
   */
  const onFormSubmit = async (
    values: ContactUsForm,
    helper: FormikHelpers<ContactUsForm>
  ) => {
    clearFormErrors(helper);
    helper.setSubmitting(true);
    onSent(false);
    setError(null);

    try {
      const recaptchaToken = await getRecaptchaToken();
      const content = `Topic: ${values.topic}\n\nMessage: ${values.message}`;
      const target = getTargetForTopic(values.topic as CONTACT_US_TOPIC);

      const body: SendContactForm = {
        name: values.fullname,
        email: values.email,
        content,
        recaptchaToken,
        target,
        company: null,
        country: null,
        website: null,
      };
      const { isSuccessful, errorMessage } = await sendApiV1Contact(
        EXTERNAL_ROUTES.API_URL,
        body
      );
      if (isSuccessful) {
        onSent(true);
      } else {
        setError(errorMessage || ERROR_MESSAGES.CONTACT_US_FAILED);
      }
    } catch (error) {
      setError(error || ERROR_MESSAGES.CONTACT_US_FAILED);
    }
    helper.setSubmitting(false);
  };

  /**
   * DOM
   */
  if (!isClient) {
    return null;
  }
  const { topic } = parse(location.search);
  const initialValues: ContactUsForm = {
    topic: (topic as string) || CONTACT_US_TOPIC.GENERAL_ENQUIRY,
    fullname: '',
    email: '',
    message: '',
    recaptchaToken: '',
  };
  return (
    <section>
      <Fade>
        <section className="grid grid-cols-5 md:grid-cols-14 gap-x-3 md:my-0 items-center z-10 type-body-copy w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({ errors, touched, handleChange, handleBlur, values, isSubmitting }) => (
              <Form
                data-testid="contact-us-form"
                className="col-start-1 col-span-full md:col-start-5 md:col-span-6 pt-8 "
              >
                <div className="flex flex-col items-center relative">
                  {error && error.length > 0 && (
                    <div className="px-4 py-2 text-failure bg-white rounded-md border pointer-events-none mb-6">
                      {error}
                    </div>
                  )}
                </div>
                <fieldset className="flex flex-wrap content-between">
                  <label className="block w-full mb-4">
                    <span className="text-sh-b2 mb-2  block">
                      Choose your type of question: *
                    </span>
                    <AppValidationWrapper
                      show={!!errors.topic && !!touched.topic}
                      message={errors.topic}
                      testid="topic-error"
                    >
                      <Field
                        as="select"
                        data-testid="topic"
                        name={nameof<ContactUsForm>('topic')}
                        className="app-input"
                      >
                        {Object.keys(CONTACT_US_TOPIC).map(item => {
                          return <option key={item}>{CONTACT_US_TOPIC[item]}</option>;
                        })}
                      </Field>
                    </AppValidationWrapper>
                  </label>
                  <label htmlFor="firstName" className="block mb-4 w-full">
                    <span className="text-sh-b2 mb-2  block">Your full name: *</span>
                    <AppValidationWrapper
                      show={!!errors.fullname && !!touched.fullname}
                      message={errors.fullname}
                      testid="fullname-error"
                    >
                      <input
                        type="text"
                        id="firstName"
                        data-testid="fullname"
                        className="app-input"
                        name={nameof<ContactUsForm>('fullname')}
                        value={values.fullname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </AppValidationWrapper>
                  </label>
                  <label htmlFor="emailAddress" className="block mb-4 w-full">
                    <span className="text-sh-b2 mb-2 block">Email: *</span>
                    <AppValidationWrapper
                      show={!!errors.email && !!touched.email}
                      message={errors.email}
                      testid="email-error"
                    >
                      <input
                        type="text"
                        data-testid="emailAddress"
                        id="emailAddress"
                        className="app-input"
                        name={nameof<ContactUsForm>('email')}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </AppValidationWrapper>
                  </label>
                  <label className="block w-full mb-4">
                    <span className="text-sh-b2 mb-2 block">Your message: *</span>
                    <AppValidationWrapper
                      show={!!errors.message && !!touched.message}
                      message={errors.message}
                      testid="message-error"
                    >
                      <textarea
                        data-testid="message"
                        name={nameof<ContactUsForm>('message')}
                        className="app-input pb-0"
                        value={values.message}
                        rows={5}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </AppValidationWrapper>
                  </label>
                  <div className="w-full text-sm italic">* Required Fields</div>
                  <div className="flex flex-col w-full">
                    <button
                      className="app-button-primary"
                      type="submit"
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                    >
                      Submit
                    </button>
                  </div>
                </fieldset>
              </Form>
            )}
          </Formik>
        </section>
      </Fade>
    </section>
  );
};

const Contact = ({ location }) => {
  /**
   * State
   */

  const [showSent, setShowSent] = useState<boolean>(false);

  /**
   * Hooks
   */
  useEffect(() => setShowSent(false), []);

  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <main className="text-primary py-20">
        <MainContent>
          <Fade>
            <ContactUsHeader />
          </Fade>
          {showSent && <ContactUsThankYou onAcknowledge={() => setShowSent(false)} />}
          {!showSent && (
            <ContactUsForm location={location} onSent={value => setShowSent(value)} />
          )}
        </MainContent>
      </main>
    </PageLayout>
  );
};

export default Contact;
